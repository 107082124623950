const Copyright = () => {
    return (
        <div className="mx-[16px] h-[69px] px-px pt-px overflow-hidden rounded-t-3xl bg-gradient-to-b from-white/10 to-white/0">
            <div className="w-full h-full bg-white/5 flex flex-row items-center justify-center text-sm text-white/75">
            © 2024 CoinDashboard. All rights reserved.
            </div>
        </div>
    )
}

export default Copyright;